import React, { useState, useCallback, useEffect } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { projects } from "../../projects"
import "./projectGrid.css"
import { useTranslation } from "react-i18next";
import SkewLoader from "react-spinners/SkewLoader";

type Project = {
  src: string,
  width: number,
  height: number,
  type: string
}

function ProjectGrid() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [projectType, setProjectType] = useState("all");
  const [projectList, setProjectList] = useState(projects)
  const { t } = useTranslation();

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const typeChange = (e: any) => {
    filterProjectList(projects, e.target.value)
    setProjectType(e.target.value);
  };

   const filterProjectList = (projects: Array<{src: string; width: number; height: number; type: string;}>, type: string) => {
    if(type === "all") {
      return setProjectList(projects);
    }
    const filteredProjects = projects.filter(p => p.type?.match(type));
    return setProjectList(filteredProjects);
  };

  const [loading, setLoding] = useState(false);

  useEffect(() => {
    setLoding(true);
    setTimeout(() => {
      setLoding(false)
    }, 1000)

  }, []);


  return (
    <>
      {
        loading ?
          <div className="app-loader">
            <SkewLoader
              color="#000000"
              size={50}
            />
          </div>
          :
          <div>
            <div className={"p-selector"}>
              <button className={projectType === "all" ? "p-b-active" : "p-b-unactive"} onClick={typeChange} value={"all"}>{t("all")}</button>
              <p className="p-text-select">|</p>
              <button className={projectType === "private" ? "p-b-active" : "p-b-unactive"} onClick={typeChange} value={"private"}>{t("private")}</button>
              <p className="p-text-select">|</p>
              <button className={projectType === "commercial" ? "p-b-active" : "p-b-unactive"} onClick={typeChange} value={"commercial"}>{t("commercial")}</button>
            </div>
            <Gallery photos={projectList} onClick={openLightbox} />
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={projectList.map(x => ({
                      ...x,
                      source: x.src,
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
      }
    </>
  );
}

export default ProjectGrid